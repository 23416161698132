// components/AddESignatureModal.js
import { Modal, Form, Input, DatePicker } from "antd";

const { TextArea } = Input;

const AddESignatureModal = ({ visible, onOk, onCancel, appendESignature }) => {
  const [form] = Form.useForm();

  // Handle form submission
  const handleOk = () => {
    form.validateFields().then((values) => {
      const newSignature = {
        signature: values?.signature,
        signature_date: values?.signature_date?.format("YYYY-MM-DD") || "",
        service_date: values?.service_date?.format("YYYY-MM-DD") || "",
        concurrence_signature: values?.concurrence_signature || "",
        concurrence_signature_date:
          values?.concurrence_signature_date?.format("YYYY-MM-DD") || "",
      };

      // Append the new signature
      appendESignature(newSignature);
      form.resetFields(); // Reset the form
      onOk(); // Close the modal
    });
  };

  return (
    <Modal
      title="Add E-Signature"
      visible={visible}
      onOk={handleOk}
      onCancel={onCancel}
      okText="Add"
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label="Signature"
          name="signature"
          rules={[{ required: true, message: "Signature is required" }]}
        >
          <TextArea placeholder="Enter signature" />
        </Form.Item>

        <Form.Item
          label="Signature Date"
          name="signature_date"
          rules={[{ required: false, message: "Signature date is required" }]}
        >
          <DatePicker style={{ width: "100%" }} />
        </Form.Item>

        <Form.Item
          label="Service Date"
          name="service_date"
          rules={[{ required: false, message: "Service date is required" }]}
        >
          <DatePicker style={{ width: "100%" }} />
        </Form.Item>

        <Form.Item
          label="Concurrence Signature"
          name="concurrence_signature"
          rules={[
            { required: false, message: "Concurrence signature is required" },
          ]}
        >
          <TextArea placeholder="Enter concurrence signature" />
        </Form.Item>

        <Form.Item
          label="Concurrence Signature Date"
          name="concurrence_signature_date"
          rules={[
            {
              required: false,
              message: "Concurrence signature date is required",
            },
          ]}
        >
          <DatePicker style={{ width: "100%" }} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddESignatureModal;
